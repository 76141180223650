import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import TextSection from "../templates/textSection";

const SectionTwo = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            industrydayNOSectionTwo {
              sectionTwo {
                title
                text
              }
            }
          }
        }
      }
    `
  );

  const siteContent = data.site.siteMetadata.industrydayNOSectionTwo;
  const sectionTwo = siteContent.sectionTwo;
  return <TextSection data={sectionTwo} />;
};

export default SectionTwo;
