import React, { Fragment } from "react";
import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import SiteFooterMap from "../components/siteFooterMap";
import HomepageBanner from "../components/homepageBanner";
import SectionOne from "../components/sectionOneNO";
import SectionTwo from "../components/SectionTwoNO";
import SectionThree from "../components/sectionThreeNO";
import SectionFour from "../components/sectionFourNO";
import SectionFive from "../components/sectionFiveNO";
import Sponsors from "../components/sponsors";
import Contact from "../components/contact";

const IndustryDayNO = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            bannerMenuNO {
              bannerContent {
                bannerButtonText
                bannerbuttonLink
                description
                image
                title
              }
              menuItems {
                menuItem
                link
              }
            }
          }
        }
      }
    `
  );

  const { site } = data;
  return (
    <Fragment>
      <HomepageBanner
        homeBannerContent={site.siteMetadata.bannerMenuNO.bannerContent}
        menuItems={site.siteMetadata.bannerMenuNO.menuItems}
        homeBannerStyle={{boxShadow:"inset 0 0 0 1000px rgb(11 18 24 / 25%)"}}
      />
      <Container className={"content"}>
        <Row>
          <Col sm={12}>
            <section id={"Oversikt"}>
              <SectionOne />
            </section>
            <section id={"VDC"}>
              <SectionTwo />
            </section>
            <section id={"Transformasjonsverksted"}>
              <SectionThree />
            </section>
            <section id={"Digitaliseringogbærekraft"}>
              <SectionFour />
            </section>
            <section id={"Bestepraksispresentasjoner"}>
              <SectionFive />
            </section>
            <section id={"Sponsorer"}>
              <Sponsors noTitle={"Sponsorer"}/>
            </section>
            <section id={"Kontakt"}>
              <Contact noTitle="Kontakt" />
            </section>
          </Col>
        </Row>
      </Container>
      <SiteFooterMap
        menuItems={site.siteMetadata.bannerMenuNO.menuItems}
        displayMap={false}
      />
    </Fragment>
  );
};

export default IndustryDayNO;